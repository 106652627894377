import SessionAPI from 'api/interfaces/SessionAPI';
import BaseWebImplementation from './Base.impl';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import {
    EULA,
    Features,
    Narrative,
    OfflineItems,
    TimeKeeperAssignment,
    TkGoals,
    TkOffice,
    User
} from 'api/types/types';
import { DateTime, DurationObject } from 'luxon';
import WebRootImpl from './Root.impl';
import ImmutableTimeEntry from 'api/immutables/ImmutableTimeEntry';
import ImmutableTemplate from 'api/immutables/ImmutableTemplate';
import ImmutableTimer from 'api/immutables/ImmutableTimer';
import { TimeEntry } from '../electron/Dexie';
import { Platform } from '../../../util/Platform';
import logger from '../../../logging/logging';

let KJUR = require('jsrsasign').KJUR;
let TabElect = require('tab-elect');
export let tabex = require('tabex');
const uuid4 = require('uuid4');

interface UrlHeader {
    Authorization: string;
    timeKeeper?: number;
}

export default class SessionImpl extends BaseWebImplementation implements SessionAPI {
    currentTimeKeeper?: number;
    axios: AxiosInstance;
    authenticated: boolean = false;
    // tslint:disable-next-line:no-any
    tabElection?: any;
    tabexClient = tabex.client();
    webSocket?: WebSocket;
    closingWebsocket: boolean = false;
    rootURI: string;
    wsURI?: string;
    // tslint:disable-next-line:no-any
    disconnectTimeout?: any;
    online = false;
    onlineStatusChange?: (online: boolean) => void;
    // tslint:disable-next-line:no-any
    syncHandler?: (data: any) => void;
    reInitHandler?: (attemptSoftLogin?: boolean) => void;
    onlineHandler?: (online: boolean) => void;
    pendingItemHandlers: (((entries: OfflineItems | undefined) => void) | null)[] = [];
    updatedTKHandlers: (((timeKeepers: TimeKeeperAssignment[]) => void) | null)[] = [];
    objectId: string;
    loginInProgress: boolean = false;
    pushNotifications: string[] = [];

    constructor(root: WebRootImpl, rootURI: string) {
        super(root);
        this.objectId = uuid4();
        localStorage.setItem('objectId', this.objectId);
        this.rootURI = rootURI;
        if (localStorage.getItem('serverUrl')) {
            this.rootURI = localStorage.getItem('serverUrl')!;
            this.wsURI = localStorage.getItem('wsUrl')!;
        }
        this.tabElection = TabElect(`leader`);
        this.tabElection.on('elected', this.electedLeader);
        this.tabElection.on('deposed', this.deposedAsLeader);
        this.tabexClient.on('sync', this.onSync);
        this.tabexClient.on('setOnline', this.setOnline);
        setInterval(() => {
            if (this.webSocket) {
                if (!localStorage.getItem('objectId')) {
                    localStorage.setItem('objectId', this.objectId);
                }
                if (this.objectId === localStorage.getItem('objectId')) {
                    if (this.webSocket.readyState === 1) {
                        this.webSocket.send('__ping__');
                        this.webSocket.onopen = () => {
                            this.tabexClient.emit('setOnline', true, true);
                        }
                        this.disconnectTimeout = setTimeout(() => {
                            this.tabexClient.emit('setOnline', false, true);
                            // this.tryOpenWebsocket();
                        }, 2000)
                    } else if (this.webSocket.readyState === 3) {
                        logger.info('setting offline because websocket ready state is 3')
                        this.tabexClient.emit('setOnline', false, true);
                        this.tryOpenWebsocket();
                    }
                }
            } else {
                this.tryOpenWebsocket();
            }
            if (localStorage.getItem('features') && localStorage.getItem('timeKeeperId') && this.online) {
                this.periodicSync();
            }
        }, 5000);
    }

    syncStatusProgressListener: (message: string, progress?: number, option?: {}) => void = () => {
        /* this function gets set by `setProgressListener` */
    }

    setProgressListener = (listener: (message: string, progress?: number, option?: {}) => void) => {
        this.syncStatusProgressListener = listener;
    }

    setOnlineStatusChangeHandler = (handler: (online: boolean) => void) => {
        this.onlineStatusChange = handler;
    }

    periodicSync() {
        if (this.canICallSync && this.authenticated) {
            this.sync();
        }
    }

    get canICallSync(): boolean {
        const getLocalTime = localStorage.getItem('lastSync') || DateTime.utc().toISO();
        const features = JSON.parse(localStorage.getItem('features') || '');
        const lastSyncedTime = DateTime.fromISO(getLocalTime);
        const currentUTCTime = DateTime.utc();
        const diff: DurationObject = currentUTCTime.diff(lastSyncedTime, 'seconds').toObject();
        // Default to 3600 secs if config item is not found for some reason.
        const configItemInSeconds = features.EpochConfigSyncPeriodInMinutes ?
            features.EpochConfigSyncPeriodInMinutes * 60 : 60 * 60;

        return diff.seconds! >= configItemInSeconds;
    }

    get isLeader(): boolean {
        // tslint:disable-next-line:no-any
        if ((window as any).process) {
            // electron so is leader
            return true;
        }
        return this.tabElection.isLeader;
    }

    setServer = async (url: string, wsURI: string) => {
        // todo get & validate server config first;
        this.rootURI = url;
        this.wsURI = wsURI;
        localStorage.setItem('serverUrl', url);
        localStorage.setItem('wsUrl', wsURI);
        await this.getFeatures();
    }

    setOnline = (status: boolean) => {
        if (this.online) {
            if (!status) {
                if (this.onlineStatusChange) {
                    this.onlineStatusChange(status);
                }
            }
        } else {
            if (status) {
                if (this.onlineStatusChange) {
                    this.onlineStatusChange(status);
                }
            }
        }
        this.online = status;
    }

    electedLeader = () => {
        if (window.location.href.includes('timersPopOut')) {
            this.tabElection.stepDown();
        }
        this.tryOpenWebsocket();
    }

    deposedAsLeader = () => {
        this.closeWs();
    }

    tryOpenWebsocket = () => {
        if (this.webSocket) {
            this.closeWs();
        }
        if (!this.authenticated || !this.isLeader) {
            return;
        }
        if (!localStorage.getItem('objectId')) {
            localStorage.setItem('objectId', this.objectId);
        }
        if (this.objectId !== localStorage.getItem('objectId')) {
            return;
        }
        // tslint:disable-next-line:variable-name
        let loc = window.location, new_uri;
        if (!this.wsURI) {
            if (loc.protocol === 'https:') {
                new_uri = 'wss:';
            } else {
                new_uri = 'ws:';
            }
            new_uri += '//' + loc.host;
            new_uri += loc.pathname + `ws/client?token=${encodeURI(this.getWSToken())}`;
        } else {
            new_uri = `${this.wsURI}/client?token=${encodeURI(this.getWSToken())}`;
        }
        new_uri = new_uri.replace(`${loc.protocol}:`, '');
        this.webSocket = new WebSocket(new_uri);
        this.webSocket.onerror = (err) => {
            if (err) {
                logger.error('Websocket error', err);
                this.tabexClient.emit('setOnline', false, true);
            }
        }
        this.webSocket.onclose = this.onCloseWs;
        this.webSocket.onmessage = this.wsMessage;
        this.webSocket.onopen = () => {
            this.tabexClient.emit('setOnline', true, true);
            logger.info('Web socket connected!');
        };
    }

    // -- UNSAFE -- //
    // tslint:disable:no-any
    onSync = (data: any) => {
        if (data.lastSync) {
            localStorage.setItem('lastSync', data.lastSync);
        }
        let timeEntries: ImmutableTimeEntry[] = data.timeEntries.map((te: any) => Object.assign(new ImmutableTimeEntry(), te));
        let templates: ImmutableTemplate[] = data.templates.map((t: any) => Object.assign(new ImmutableTemplate(), t));
        let narratives: Narrative[] = data.glossaries.map((n: any) => Object.assign(new Narrative(), n));
        let timers: ImmutableTimer[] = data.timers.map((t: any) => Object.assign(new ImmutableTimer(), t));
        let userDictionaries = data.userDictionaries ? data.userDictionaries : [];
        let timeKeepers = data.timeKeepers ? data.timeKeepers : [];
        let timeCastSegments = data.timeCastSegments ? data.timeCastSegments : [];
        let settings = data.settings ? data.settings : [];
        if (templates.length > 0) {
            this.root.Template.recieve(templates);
        }
        if (timeEntries.length > 0) {
            this.root.TimeEntry.recieve(timeEntries);
        }
        if (timers.length > 0) {
            this.root.Timer.recieve(timers);
        }
        if (narratives.length > 0) {
            this.root.Narrative.recieve(narratives);
        }
        if (data.matters || data.matterTkMappings) {
            this.root.Matter.recieve(data.matters);
        }
        if (timeKeepers.length > 0) {
            this.updatedTKHandlers.filter(h => h !== null)
                .forEach(async h => h!(await this.getTimekeeperAssignments()));
        }
        if (timeCastSegments.length > 0) {
            this.root.TimeCast.recieveSegments(timeCastSegments);
        }
        if (settings.length > 0) {
            this.root.Settings.receiveSettings(settings);
        }
        if (userDictionaries.length > 0) {
            this.root.CustomDictionary.recieve(userDictionaries);
        }
        if (this.syncHandler) {
            let syncTimeEntries = timeEntries.map((te: any) => {
                let syncTE = te.toWriteable() as TimeEntry;
                if (te.localId) {
                    syncTE.localId = te.localId;
                }
                syncTE.serverDirty = te.serverDirty;
                return syncTE;
            });
            let syncData = {
                ...data,
                timeEntries: syncTimeEntries
            };
            this.syncHandler(syncData);
        }
    }
    // tslint:enable:no-any

    wsMessage = async (data: { data: string }) => {
        if (data.data === '__pong__') {
            this.tabexClient.emit('setOnline', true, true);
            clearInterval(this.disconnectTimeout)
            return;
        }
        if (this.loginInProgress) {
            this.pushNotifications.push(data.data);
            return ;
        }
        let resp = await this.axios.get(`/sync`);
        this.tabexClient.emit('sync', resp.data, true);
    }

    syncLastPushNotification = () => {
        if (this.pushNotifications.length > 0) {
            this.sync();
        }
        this.pushNotifications = [];
    }

    onCloseWs = () => {
        if (this.closingWebsocket) {
            this.closingWebsocket = false;
            return;
        }
        this.webSocket = undefined;
        // setTimeout(this.tryOpenWebsocket, 10000);
    }

    closeWs = () => {
        if (this.webSocket) {
            this.closingWebsocket = true;
            this.webSocket.close();
        }
    }

    setReinitializeHandler = (handler: (attemptSoftLogin?: boolean) => void) => {
        this.reInitHandler = handler;
        this.tabexClient.on('reinit', this.reInitHandler);
    };

    logIn = async (user: string, password: string) => {
        let reqObj = {
            username: user,
            password,
            device: {
                type: Platform.isElectron() ? 'DESKTOP' : 'WEB'
            }
        };
        this.loginInProgress = true;
        this.syncStatusProgressListener('app.login.progress.awaiting_response');
        let resp = await axios.post(`${this.rootURI}/user/login`, reqObj);
        this.setSessionItems(resp);
        if (Platform.isWeb()) {
            this.loginInProgress = false;
        }
        return;
    }

    ssoLogin = async (token: string) => {
        try {
            this.loginInProgress = true;
            let reqObj = {
                token,
                device: {
                    type: Platform.isElectron() ? 'DESKTOP' : 'WEB'
                }
            };
            this.syncStatusProgressListener('app.login.progress.awaiting_response');
            let resp: AxiosResponse = await axios.post(`${this.rootURI}/oidc/login`, reqObj);
            this.setSessionItems(resp);
        } catch (e) {
            // logger.error('Web', e);
            throw e;
        } finally {
            if (Platform.isWeb()) {
                this.loginInProgress = false;
            }
        }
    }

    setSessionItems = (resp: AxiosResponse) => {
        let {refreshToken, authToken, username, displayname, pushToken, userId, sessionId, permissions, roles,
            reportingBaseUrl, approvalConfig} = resp.data;

        if (localStorage.getItem('timeKeeperId')) {
            localStorage.removeItem('timeKeeperId');
            this.currentTimeKeeper = undefined;
        }
        localStorage.setItem('displayName', displayname);
        localStorage.setItem('userName', username);
        localStorage.setItem('token', authToken);
        localStorage.setItem('pushToken', pushToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('userId', userId);
        localStorage.setItem('sessionId', sessionId);
        localStorage.setItem('permissions', JSON.stringify(permissions));
        localStorage.setItem('roles', JSON.stringify(roles));
        localStorage.setItem('reportingBaseUrl', reportingBaseUrl);
        localStorage.setItem('approvalConfig', JSON.stringify(approvalConfig));
        this.authenticated = true;
        this.tabexClient.emit('reinit', undefined);
        this.buildAxios();
    }

    resetState = (allTabs: boolean = false, attemptSoftLogin: boolean = false) => {
        if (attemptSoftLogin) {
            this.tabexClient.emit('reinit', attemptSoftLogin, allTabs);
            return;
        }
        this.authenticated = false;
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('pushToken');
        localStorage.removeItem('userId');
        localStorage.removeItem('sessionId');
        localStorage.removeItem('timeKeeperId');
        localStorage.removeItem('displayName');
        localStorage.removeItem('userName');
        localStorage.removeItem('toggletcview');
        localStorage.removeItem('permissions');
        localStorage.removeItem('roles');
        localStorage.removeItem('superset-access-token');
        localStorage.removeItem('superset-refresh-token');
        this.tabexClient.emit('reinit', attemptSoftLogin, allTabs);
        this.currentTimeKeeper = undefined;
    }

    buildAxios = () => {
        let token = localStorage.getItem('token');
        const headers: UrlHeader = { Authorization: 'Bearer ' + token };
        if (this.currentTimeKeeper) {
            headers.timeKeeper = this.currentTimeKeeper;
        }
        this.axios = axios.create({
            baseURL: this.rootURI,
            headers: headers
        });
        this.axios.interceptors.response.use(undefined, this.failureInterceptor.bind(this));
    }

    failureInterceptor = async (error: AxiosError) => {
        let response = error.response;
        logger.error(error);
        const token = localStorage.getItem('token');

        if (response && response!.status === 401) {
            this.resetState(true, !!token);
            this.authenticated = false;
            return Promise.reject(error);
        }

        if (response && response!.status === 403) {
            await this.doRefreshToken();
            error.config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
            error.config.baseURL = undefined;
            return axios.request(error.config);
        }
        return Promise.reject(error);
    }

    getRefreshToken = () => {
        let refreshToken = localStorage.getItem('refreshToken');
        let userId = Number(localStorage.getItem('userId'));
        let sessionId = Number(localStorage.getItem('sessionId'));

        let oHeader = {alg: 'HS256', typ: 'JWT', kid: sessionId.toString()};
        // tslint:disable-next-line:no-any
        let oPayload: any = {
            userId,
            sessionId
        };
        let tNow = KJUR.jws.IntDate.get('now');
        let tEnd = tNow + 300;
        oPayload.aud = 'EpochServer';
        oPayload.sub = Platform.isWeb() ? 'Epoch_WEB' : 'Epoch_DESKTOP';
        oPayload.nbf = tNow;
        oPayload.iat = tNow;
        oPayload.exp = tEnd;
        let sHeader = JSON.stringify(oHeader);
        let sPayload = JSON.stringify(oPayload);
        return KJUR.jws.JWS.sign('HS256', sHeader, sPayload, refreshToken);
    }

    getWSToken = () => {
        let pushToken = localStorage.getItem('pushToken');
        let userId = Number(localStorage.getItem('userId'));
        let sessionId = Number(localStorage.getItem('sessionId'));

        let oHeader = {alg: 'HS256', typ: 'JWT'};
        // tslint:disable-next-line:no-any
        let oPayload: any = {
            userId,
            sessionId
        };
        let tNow = KJUR.jws.IntDate.get('now');
        let tEnd = tNow + 300;
        oPayload.aud = 'Piston';
        oPayload.sub = Platform.isWeb() ? 'Epoch_WEB' : 'Epoch_DESKTOP';
        oPayload.nbf = tNow;
        oPayload.iat = tNow;
        oPayload.exp = tEnd;
        // Sign JWT, password=616161
        let sHeader = JSON.stringify(oHeader);
        let sPayload = JSON.stringify(oPayload);
        return KJUR.jws.JWS.sign('HS256', sHeader, sPayload, pushToken);
    }

    doRefreshToken = async () => {
        let refToken = this.getRefreshToken();
        try {
            let response = await this.axios.get(`${this.rootURI}/user/refreshToken?token=${refToken}`);
            localStorage.setItem('token', response.data.authToken);
            this.axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
        } catch (err) {
            // logger.info('Refresh token error', err);
            this.resetState(true, !!refToken);
            this.authenticated = false;
        }
    }

    initialize = async () => {
        let token = localStorage.getItem('token');
        let refToken = localStorage.getItem('refreshToken');
        if (!token || !refToken) {
            return false;
        }
        let timeKeeperId = localStorage.getItem('timeKeeperId') || undefined;
        if (timeKeeperId) {
            this.setTimeKeeper(Number(timeKeeperId));
        }
        let us = await this.check();
        if (!us) {
            this.resetState();
            return false;
        }
        this.authenticated = true;
        this.tryOpenWebsocket();
        return us;
    }

    check = async (): Promise<boolean> => {
        this.buildAxios();
        let response: AxiosResponse = await this.axios.get(`/user/me`);
        localStorage.setItem('valid', response.data.valid);
        return response.status === 200 && response.data.valid === true;
    }

    getTimekeeperAssignments = async (id?: number): Promise<TimeKeeperAssignment[]> => {
        let resp = await this.axios.get(`/user/timeKeeperAssignments${id ? '?userId=' + id : ''}`);
        return resp.data;
    }

    getTimeKeeperOffices = async (tkId: number): Promise<TkOffice[]> => {
        let userId = localStorage.getItem('userId');
        let resp = await this.axios.get(`timekeeper/offices?timekeeperid=${tkId}&userId=${userId}`);
        return resp.data;
    }

    setTimeKeeper = async (timeKeeperId: number) => {
        this.currentTimeKeeper = timeKeeperId;
        localStorage.setItem('timeKeeperId', timeKeeperId.toString());
        return;
    }

    me = async (): Promise<User> => {
        let localStorageVariable = localStorage;
        return {
            displayName: localStorageVariable.displayName,
            name: localStorageVariable.userName,
            id: localStorageVariable.userId,
            valid: localStorageVariable.valid
        };
    }

    logOut = async () => {
        try {
            await this.axios.get(`/user/logout`);
            this.closeWs();
        } catch (e) {
            // logger.error('logout error', e);
            throw e;
        } finally {
            this.resetState(true);
            return;
        }
    }

    getFeatures = async () => {
        const features = JSON.parse(localStorage.getItem('features') || '-1');
        if (features === -1) {
            return await this.getFeaturesApi();
        }
        return features;
    }

    getFeaturesApi = async () => {
        try {
            let { data, request} = await axios.get(`${this.rootURI}/features`);
            if (typeof data !== 'object') {
                // redirect to external login page
                window.location.href = request.responseURL;
                return;
            }
            localStorage.setItem('features', JSON.stringify(data));
            return data as Features;
        } catch (e) {
            const features = localStorage.getItem('features');
            if (features === null) {
                throw e;
            }
            return JSON.parse(features);
        }
    }

    getEulaText = async (): Promise<EULA> => {
        let token = localStorage.getItem('token');
        let resp = await axios.get(`${this.rootURI}/EULA`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return {
            eulaText: resp.data,
            lastModified: resp.headers['last-modified']
        };
    }

    ssoEntry = async () => {
        window.location.href = `${this.rootURI}/oidc/idp/entry?referer=${window.location.href}`;
    }

    async sync() {
        if (this.axios && !this.loginInProgress) {
            let {data} = await this.axios.get(`/sync`);
            await this.onSync(data);
            // await this.updatedTKHandlers.filter(h => h !== null)
            //     .forEach(async h => h!(await this.getTimekeeperAssignments()))
        }
    }

    pendingItemsReciever = (handler: (entries: OfflineItems | undefined) => void) => {
        this.pendingItemHandlers.push(handler);
        const theIndex = this.pendingItemHandlers.length - 1;
        return () => {
            this.pendingItemHandlers[theIndex] = null;
        };
    }

    updatedTKsReciever = (handler: (tks: TimeKeeperAssignment[]) => void) => {
        this.updatedTKHandlers.push(handler);
        const theIndex = this.updatedTKHandlers.length - 1;
        return () => {
            this.updatedTKHandlers[theIndex] = null;
        };
    }

    silentSSOLogin = async (features: Features) => {
        if (features.EpochConfigKerberosEnabled) {
            await this.kerberosLogin();
        } else {
            await this.ssoEntry();
        }
    }

    kerberosLogin = async () => {
        // this.buildAxios();
        try {
            this.loginInProgress = true;
            let reqObj = {
                device: {
                    type: Platform.isElectron() ? 'DESKTOP' : 'WEB'
                }
            };
            let resp = await axios.post(`${this.rootURI}/user/kerberos`, reqObj);
            await this.setSessionItems(resp);
        } catch (e) {
            // logger.error('Kerberos login error', e);
            throw e;
        } finally {
            if (Platform.isWeb()) {
                this.loginInProgress = false;
            }
        }
    }

    getTkGoals = async (year: number): Promise<TkGoals | undefined> => {
        try {
            let resp: AxiosResponse = await this.axios.get(`/tkgoals/tk/${this.currentTimeKeeper}?year=${year}`);
            return resp.data[0];
        } catch (e) {
            throw e;
        }
    }

    get serverSet() {
        return !!localStorage.getItem('serverUrl');
    }

    getAllTimeKeepersList = async (search: string, offset: number, limit: number, workDate: string, matterId?: number | null) => {
        workDate = DateTime.fromISO(workDate).toISODate();
        const {data} = await this.axios.get(
            `/timeKeepers?search=${encodeURIComponent(search)}` +
            `&offset=${offset}&limit=${limit}&workDate=${workDate}` +
            `${matterId ? `&matterId=${matterId}` : ``}`
        );
        return data;
    }
}
